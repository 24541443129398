import React from 'react'
import PropTypes from 'prop-types'

import { css } from '@emotion/core'
import tw from 'twin.macro'

import LogoImg from '../svgs/logo.svg'

const HomepageIntro = ({ style }) => (
  <div
    tw="fixed left-0 top-0 w-full h-full flex justify-center items-center pointer-events-none z-40 opacity-0 transition-opacity duration-1000 bg-transparent"
    style={style}
  >
    <LogoImg
      tw="max-w-full"
      css={css`
         {
          width: 636px;
          height: 46px;
        }
      `}
    />
  </div>
)

HomepageIntro.propTypes = {
  style: PropTypes.object,
}

HomepageIntro.defaultProps = {
  style: {},
}

export default HomepageIntro
