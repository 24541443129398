import React from 'react'
import PropTypes from 'prop-types'

import 'twin.macro'

const TransitionToProjectOverlay = ({ projectName }) => (
  <div tw="fixed left-0 top-0 w-full h-full flex justify-center items-center pointer-events-none bg-transparent">
    <div
      tw="text-4xl md:text-5xl lg:text-6xl text-black leading-snug font-normal opacity-0"
      data-gsap="project-title"
    >
      {projectName}
    </div>
  </div>
)

TransitionToProjectOverlay.propTypes = {
  projectName: PropTypes.string,
}

TransitionToProjectOverlay.defaultProps = {
  projectName: '',
}

export default TransitionToProjectOverlay
