import React, { useState, useEffect } from 'react'
import {
  Transition,
  CSSTransition,
  SwitchTransition,
} from 'react-transition-group'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'

import Loadable from '@loadable/component'

import gsap from 'gsap'

import 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SEO from '../components/seo'
import HomepageIntro from '../components/homepage-intro'
import CircleButton from '../components/circle-button'
import ButtonLink from '../components/button-link'
import TransitionToProjectOverlay from '../components/transition-to-project-overlay'
import HomepageListView from '../components/homepage-list-view'

import {
  inTransition,
  inDuration,
} from '../transitions/homepage-to-project-transition'

const HomepageGrid = Loadable(() => import('../components/homepage-grid'))

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
}

const IndexPage = ({ data, location }) => {
  const { entry } = data

  const [projectName, setProjectName] = useState('')
  const [currentView, setCurrentView] = useState('intro')
  const [showIntro, setShowIntro] = useState(false)
  const [resettingGrid, setResettingGrid] = useState(false)

  useEffect(() => {
    if (
      (!location.state || !location.state.referrer) &&
      currentView === 'intro'
    ) {
      setTimeout(() => {
        setShowIntro(true)
      }, 100)

      setTimeout(() => {
        setCurrentView('grid')
        setShowIntro(false)
      }, 2400)
    } else {
      setCurrentView('grid')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let resizeTimer = null

    const onResize = () => {
      if (currentView === 'grid') {
        if (resizeTimer) clearTimeout(resizeTimer)
        resizeTimer = setTimeout(() => {
          setResettingGrid(true)
          setTimeout(() => {
            setResettingGrid(false)
          }, 400)
        }, 200)
      } else {
        setResettingGrid(false)
      }
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return (
    <div tw="min-h-screen">
      <Helmet
        bodyAttributes={{
          class: currentView === 'list' ? 'no-scroll-not-mobile' : 'no-scroll',
        }}
      />
      <SEO data={entry.seomatic} />
      <Transition
        in={showIntro}
        timeout={300}
        onEntering={node => {
          const logoEl = node.querySelector('svg')
          gsap.set(logoEl, { scale: 0.7 })
          gsap.to(logoEl, { scale: 0.9, duration: 3 })
        }}
      >
        {state => <HomepageIntro style={{ ...transitionStyles[state] }} />}
      </Transition>
      {currentView !== 'intro' && (
        <motion.div
          tw="fixed w-full left-0 top-0 py-4 z-20 flex justify-center pointer-events-none"
          data-gsap="top-ui"
          animate={{ y: 0 }}
          initial={{ y: -100 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            delay: 1.2,
          }}
        >
          <div tw="mr-4">
            <ButtonLink
              to="/about/"
              text="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;About us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              hoverText="Places with Life & Soul"
              bgColour="white"
              inTransition={inTransition}
              inDuration={inDuration}
            />
          </div>
          <CircleButton
            bgColour="white"
            label={currentView === 'grid' ? 'List View' : 'Grid View'}
            onClick={() => {
              setCurrentView(currentView === 'grid' ? 'list' : 'grid')
            }}
          >
            <FontAwesomeIcon
              icon={[
                'fal',
                currentView === 'grid' ? 'list-ul' : 'grip-horizontal',
              ]}
            />
          </CircleButton>
        </motion.div>
      )}
      {currentView !== 'intro' && !resettingGrid && (
        <SwitchTransition>
          <CSSTransition
            timeout={{
              enter: 0,
              exit: 400,
            }}
            key={currentView}
            classNames="fade"
          >
            {currentView === 'grid' ? (
              <HomepageGrid
                grid={entry.homepageGrid}
                setProjectName={setProjectName}
              />
            ) : (
              <HomepageListView projects={entry.listViewProjects} />
            )}
          </CSSTransition>
        </SwitchTransition>
      )}
      <TransitionToProjectOverlay
        projectName={projectName}
      ></TransitionToProjectOverlay>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query($id: ID) {
    entry: craftHomepageHomepageEntry(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
      homepageGrid {
        ... on Craft_homepageGrid_row_BlockType {
          id
          block {
            ... on Craft_block_BlockType {
              id
              imageSquare {
                ...ImagesHomepageGridSquare
              }
              imageLandscape {
                ...ImagesHomepageGridLandscape
              }
              project {
                title
                uri
              }
              alignSquareTo
            }
          }
        }
      }
      listViewProjects {
        title
        uri
        ... on Craft_project_project_Entry {
          id
          homepageListViewImages {
            ...ImageArticleSquare
          }
        }
      }
    }
  }
`
